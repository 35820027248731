import dynamic from 'next/dynamic';
import { GetServerSideProps } from 'next';
import FeaturedLastNews from '@components/Home/FeaturedLastNews';
import { api, mimir, site } from '@/services/api';
import { TimeLine } from '@/types/types';
import { AdContainer } from '@/components/Layout/ItemListLastNews/styles';
import PodcastItem, { PodcastData } from '@/components/Layout/Podcast';
import { useRouter } from 'next/router';
import { SchemaType } from '@/components/Internals/InternalSchema';
import { BreadcrumbItem } from '@/components/Layout/Breadcrumb';
import getEnv from '@/utils/getEnv';
import getConcurrentPromises from '@/utils/getConcurrentPromises';
import { NavBarContentData, TimeLineApi } from '@/shared/types/home.types';
import Layout from '@/components/Layout';

export type Vitrine = {
  tipo: string;
  titulo: string;
  categoria: string;
  autor: string;
  url: string;
  imagem: {
    url: string;
    blurhash: string;
  };
  data: string;
  idade: string;
};

export type HeadTags = {
  tag: string;
  content: string;
  attr: {
    rel: string;
    href: string;
    type: string | undefined;
    title: string | undefined;
    name: string | undefined;
    content: string | undefined;
    property: string | undefined;
    'http-equiv': string | undefined;
  };
};
interface LastNewsItems {
  itens: TimeLine[];
  paginacao: string;
}

interface FeaturedNewsType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navBarData: any;
  tagsData: HeadTags[];
  homeData: {
    porta101?: PodcastData;
    canaltech?: PodcastData;
    timeline?: LastNewsItems;
  };
  segmentacoes: [];
  breadcrumbData: {
    titulo: string;
    url: string;
  }[];
  timelineData: {
    nome: string;
    titulo: string;
    vitrine: Vitrine[];
    timeline: LastNewsItems;
  };
  schema?: SchemaType;
}

interface FeaturedNewsApi {
  data: {
    vitrine: Vitrine[];
    timeline: TimeLine[];
  };
  ssr: HeadTags[];
  segmentacoes: [];
  headers: {
    'Cache-control': string;
    Expires: string;
  };
  breadcrumbData: BreadcrumbItem[];
  schema?: SchemaType;
}

export default function PodcastPlaylist({
  homeData,
  navBarData,
  tagsData,
  segmentacoes,
  timelineData,
  breadcrumbData,
  schema = null,
}: FeaturedNewsType): JSX.Element {
  const AdsTypeGenerators = dynamic(() => import('@/components/Ads/Google/AdsTypeGenerators'), { ssr: false });
  const RSSButtonNoSSR = dynamic(() => import('@components/Layout/RSSButton'), { ssr: false });

  const router = useRouter();
  const { playlists } = router.query;

  const title = `Mais Recentes`;

  return (
    <Layout layoutData={{ segmentacoes, tagsData, navBarData, schema }}>
      <PodcastItem podcastData={homeData} breadcrumbs={breadcrumbData} />
      <AdContainer noPadding billboard>
        <AdsTypeGenerators type="billboard/main/listing" />
      </AdContainer>
      <FeaturedLastNews
        featuredLastNewsData={timelineData.timeline}
        rootPage={`podcast/${playlists}`}
        textTitle={title}
        headingTag="h1"
        route={`podcast/${playlists}`}
        routeType="tipo"
      />
      <RSSButtonNoSSR link={`${getEnv('URL')}/rss/podcast/${playlists}/`} />
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps = async context => {
  const { host } = context.req.headers;
  const { playlists } = context.params;

  const mainReq = { promise: site.get<FeaturedNewsApi>(`/podcast/${playlists}`), link: `/podcast/${playlists}` };
  const timelineReq = {
    promise: mimir.get<TimeLineApi>(`/timelines/ultimas/tipo/podcast/${playlists}`),
    link: `/timelines/ultimas/tipo/podcast/${playlists}`,
  };
  const responseMenuReq = { promise: api.get<NavBarContentData>(`/componentes/menu/`), link: `/componentes/menu/` };

  const { promiseData, data, statusCode } = await getConcurrentPromises({
    mainPromise: mainReq,
    navBarPromise: responseMenuReq,
    timelinePromise: timelineReq,
    host,
  });

  if (data.redirect || data.notFound) return data;

  const homeData = data.data;
  const schema = data?.schema;
  const navBarData: NavBarContentData = promiseData[responseMenuReq.link].data.data;
  const tagsData = data.ssr;
  const { segmentacoes } = data;
  const timelineData = promiseData[timelineReq.link].data.data;
  const breadcrumbData = data.breadcrumb;

  context.res.setHeader('Cache-Control', data.headers['Cache-control']);
  context.res.setHeader('Expires', data.headers.Expires);
  context.res.statusCode = statusCode;

  return {
    props: {
      tagsData,
      homeData,
      schema,
      navBarData,
      segmentacoes,
      timelineData,
      breadcrumbData,
    },
  };
};
